.searchWrapper {
  flex: 1;
  min-width: 140px;
  position: relative;
  height: 52px;
}

.headerNav {
  position: sticky;
  top: 0;
  z-index: 20;
}
