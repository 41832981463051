.App {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  position: relative;
}

ol.carousel-indicators li {
  background-color: black;
}

/*Replace the default icons*/
/*Inspired by: https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel*/
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
}
.carousel-control-next-icon:after {
  content: ">";
  font-size: 30px;
  font-weight: bold;
  color: black;
}
.carousel-control-prev-icon:after {
  content: "<";
  font-size: 30px;
  font-weight: bold;
  color: black;
}

.cleanLink {
  color: inherit;
  text-decoration: none;
}
.cleanLink:hover {
  color: inherit;
  text-decoration: none;
}
