.searchBar {
  width: 100%;
  border: 1px solid grey;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: absolute;
  z-index: 15;
  top: 0;
}

.searchBar input {
  width: 100%;
  border: none;
  margin: 0;
  padding: 10px;
  font-size: 20px;
  box-sizing: border-box;
  z-index: inherit;
  outline: none;
  border-radius: 10px;
}

.searchBar ul {
  margin: 0;
  padding: 0;
  background-color: white;
  text-align: left;
  list-style-type: none;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid grey;
}

.searchBar ul::before {
  content: "";
}

.searchBar li {
  padding: 10px;
  overflow: hidden;
}

.searchBar li:hover {
  background-color: rgba(218, 213, 213, 0.391);
}
